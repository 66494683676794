document.addEventListener('DOMContentLoaded', () => {
    const optinInputs: NodeListOf<HTMLInputElement> | null = document.querySelectorAll('.optin-input');

    optinInputs?.forEach(optinInput => {
        const input: HTMLInputElement | null = optinInput.querySelector('input[name="mailing_ok"]');
        input?.addEventListener('change', (e) => {
            e.stopPropagation();
            input.checked = !input.checked;
        })
        
        optinInput?.addEventListener('click', () => {
            const input: HTMLInputElement | null = optinInput.querySelector('input[name="mailing_ok"]');
            
            if(!!input) {
                input.checked = !input.checked;
            }
        })
    })

});