import { handleFormSubmit, handleClickOutsideForm } from '@/utils/forms';

document.addEventListener('DOMContentLoaded', () => {
    const formModal: HTMLElement | null = document.querySelector('.vendreTerrainFormModal')
    const form: HTMLElement | null = document.querySelector('#contactform_ground')
    const submitBtn: HTMLElement | null = document.querySelector('.submitTerrainFormBtn')
    const openVendreTerrainFormBtn: NodeListOf<HTMLElement> | null = document.querySelectorAll('.openFormModalBtn')
    const closeVendreTerrainFormBtn: HTMLElement | null = document.querySelector('#closeTerrainForm')

    if(!formModal) return;

    openVendreTerrainFormBtn?.forEach(btn => {
        btn.addEventListener('click', (e) => {
            e.stopPropagation();
            if(formModal) {
                formModal.classList.toggle('hidden');
            }

            if(formModal && !formModal.classList.contains('hidden')) {
                handleClickOutsideForm(formModal);
            }
        })
    })

    closeVendreTerrainFormBtn?.addEventListener('click', () => {
        formModal.classList.add('hidden');
    })

    form?.addEventListener('submit', (e) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        if(!formData) return;
        handleFormSubmit(formData, submitBtn, 'vendreTerrainFormModal', 'terrain', {
            form: {
                id: "Terrain à vendre",
                title: "Terrain à vendre"
            }
        });
    })
})