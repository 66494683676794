import axios from "axios"
// import Splide from '@splidejs/splide';
// import '@splidejs/splide/css';

import Swiper from 'swiper/bundle'
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


document.addEventListener('DOMContentLoaded', async () => {
    const immodvisorsContainer: HTMLElement | null = document.querySelector('.immodvisors-container');
    const immodvisorsPlaceholder: HTMLElement | null = document.querySelector('.immodvisors-placeholder');
    
    if(immodvisorsContainer) {
        // await getImmodvisorsReview()
        await getImmodvisorsRating()

        immodvisorsContainer.classList.remove('hidden');
        immodvisorsPlaceholder?.classList.add('hidden');
    }

    new Swiper('.immodvisors-slider', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    })

    immodvisorsContainer?.querySelectorAll('.review-content')?.forEach((reviewContent) => {
        reviewContent.addEventListener('click', () => {
            displayReviewContent(reviewContent);
        })
    });
})

const displayReviewContent = (item: Element) => {
    item.classList.toggle('line-clamp-3');
}

/**
* 
* We keep this function in case we need to fetch reviews from immodvisors
* Client decided to handle reviews manually for now
*
*/
// const getImmodvisorsReview = async () => {
//     try {
//         //@ts-ignore
//         const response = await axios.get(`${urls.ajax}?action=fetch_reviews_from_api`)
//         console.log('response from immodvisors reviews', response);
//     } catch (error) {
//         console.error(error);
//     }
// }

const getImmodvisorsRating = async () => {
    try {
        //@ts-ignore
        const response = await axios.get(`${urls.ajax}?action=fetch_rating_from_api`)

        if(response.status !== 200) {
            throw new Error('Error fetching immodvisors rating');
        }

        const data = response.data.datas?.company;
        const lncImmodvisorsBlock: HTMLElement | null = document.querySelector('.immodvisors-widget-container');
        const loadingPlaceholder: HTMLElement | null = document.querySelector('.immodvisors-placeholder');

        if(!lncImmodvisorsBlock || !loadingPlaceholder || !data) return;

        const immodvisorsRating: HTMLElement | null = document.querySelector('.immodvisors-rating');
        const immodvisorsRatingStars: SVGElement | null = document.querySelector('.immodvisors-rating-stars');
        if(immodvisorsRating) {
            immodvisorsRating.textContent = `${data?.rating}/5`;
            for(let i = 0; i < Math.ceil(data?.rating); i++) {
                immodvisorsRatingStars?.children[i].classList.remove('hidden');
            }
        }

        const immodvisorsRatingCount: HTMLElement | null = document.querySelector('.immodvisors-reviews');
        
        if(immodvisorsRatingCount) {
            immodvisorsRatingCount.textContent = `${data?.reviews?.recommended || data?.nbr_reviews_google} avis clients`;
        }

        lncImmodvisorsBlock.classList.remove('hidden');

    } catch (error) {
        console.error(error);
    }
}
