// Default theme
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

class ResidencesCategoriesHomepage {
    private container: HTMLElement | null;

    constructor() {
        this.container = document.querySelector('.homepageResidencesCategories');

        const statusSliders: NodeListOf<HTMLElement> | null = document.querySelectorAll('.categoriesOffersSlider');
        if(statusSliders) {
            this.setupSplides(statusSliders)
            statusSliders.forEach((sliderContainer: HTMLElement) => {
                const programStatus = sliderContainer.dataset.programStatus || '';
                sliderContainer.setAttribute('data-program-status', programStatus);
            });

            this.displayCategoryPrograms()
        };

    }

    private displayCategoryPrograms(): void {
        let currentCategory: string | undefined = 'lancement';

        if (!!this.container) {
            const categories: NodeListOf<HTMLElement> = this.container.querySelectorAll('[data-residence-category]');
            const programsCards: NodeListOf<HTMLElement> = document.querySelectorAll('[data-program-status]');

            categories.forEach((category: HTMLElement) => {
                category.addEventListener('click', function () {
                    currentCategory = category.dataset.residenceCategory

                    const filterResidencesBtn = document.querySelector('.filterResidences')
                    if(filterResidencesBtn) {
                        filterResidencesBtn.setAttribute('href', `/immobilier-neuf/?status=${currentCategory}`)
                    }

                    categories.forEach((category: HTMLElement) => {
                        category.classList.remove('active');
                    });

                    programsCards.forEach((card: HTMLElement) => {
                        if(card.dataset.programStatus !== currentCategory) {
                            card.classList.add('hidden');
                        } else {
                            card.classList.remove('hidden');
                        }
                    });

                    category.classList.add('active');
                });
            });
        }
    }

    private setupSplides(statusSliders: NodeListOf<HTMLElement>): void {
        statusSliders.forEach((sliderContainer: HTMLElement) => {
            const splide = new Splide(sliderContainer, {
                breakpoints: {
                    0: {
                    height: this.setSliderHeight(),
                    width: '100vw',
                    },
                },
                classes: {
                    pagination: 'splide__pagination slider-pagination-container pagination-residences-categories max-md:!relative max-md:!pt-6 !flex !justify-center !items-center !block',
                    page: 'splide__pagination__page slider-pagination-items',
                },
                perPage: this.setSliderPerPage(),
                dataAttribute: 'data-program-status'
            })

            splide.mount();

        });
    }

    private setSliderPerPage = () => {
        return window.innerWidth > 1920 ? 4
            : window.innerWidth > 1024 ? 4
                : window.innerWidth > 500 ? 2 : 1;
    }

    private setSliderHeight = () => {
        return window.innerWidth < 1024 ? '650px' : '';
    }

    public static init(): void {
        document.addEventListener("DOMContentLoaded", function () {
            new ResidencesCategoriesHomepage();
        });
    }
}

ResidencesCategoriesHomepage.init();
