import axios from 'axios'

type Location = {
    value: string;
    key: string
}

export class LocationField {
    private inputsList: NodeListOf<HTMLInputElement> | null;
    private locations: Array<Location> = [];
    private loader!: HTMLElement | null;

    constructor() {
        this.inputsList = document.querySelectorAll('.locationField');

        if (!this.inputsList) return;

        this.inputsList.forEach((element: HTMLInputElement) => {
            const input: HTMLInputElement | null = element.querySelector('input');
            this.loader = element.querySelector('.locationField-loader');

            if(!input) return;

            this.setupLocationField(element, input);
        })
    }

    private handleLoading = () => {
        this.loader?.classList.toggle('hidden');
    }

    private handleClickOutside = (element: HTMLElement, input: HTMLInputElement) => {
        document.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;

            if (!element.contains(target) && target !== input) {
                element.classList.add('hidden');
            }
        })
    }

    private setupLocationField(element: HTMLElement, input: HTMLInputElement): void {
        input?.addEventListener('input', (event) => this.fetchLocations(element, input, event));
    }

    private fetchLocations = async (element: HTMLElement, input: HTMLInputElement, event: Event) => {
        try {
            this.handleLoading();
            const target = event.target as HTMLInputElement;
            const query = target.value;

            if (query.length < 2) return;

            // @ts-ignore
            const response = await axios.get(`${urls?.ajax}?action=search_autocomplete&search=${query}`)

            if(response.status !== 200) throw new Error('Failed to fetch locations');

            this.locations = response.data;
            
            this.displayResults(element, input);

        } catch (error) {
            console.error(error);
        } finally {
            this.handleLoading()
        }
    }

    private displayResults(element: HTMLElement, input: HTMLInputElement): void {
        const resultsContainer: HTMLElement | null = element.querySelector('.location_results');

        if (!resultsContainer) return;

        this.handleClickOutside(resultsContainer, input);

        resultsContainer.innerHTML = '';

        // Display empty results in case of no locations
        if (this.locations.length === 0) {
            const result = document.createElement('li');
            result.classList.add('location_result', 'p-2');
            result.innerHTML = 'Aucun résultat';
            resultsContainer.appendChild(result);
            return;
        } else {
            this.locations?.forEach((location: Location) => {
                const result = document.createElement('li');
                result.classList.add('location_result', 'cursor-pointer', 'p-2', 'hover:text-red');
                result.innerHTML = location.value;
                resultsContainer.appendChild(result);

                result.addEventListener('click', () => {
                    input.value = location.value;
                    resultsContainer.classList.add('hidden');
                })
            })
        }

        resultsContainer.classList.remove('hidden');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new LocationField();
})