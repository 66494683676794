import axios from 'axios'
import { gtmEvent } from '../../scripts/includes/gtm';

interface GtmPayload {
    form: {
        id: string;
        title: string;
    };
    success: boolean;
    program?: {
        id: string;
        title: string;
    };
}

export const displayFormSubmitMessage = (type: 'success' | 'error', message: string) => {
    const messageContainer: HTMLElement | null = document.querySelector('.submit_form_message');

    if(!messageContainer) return;

    messageContainer.classList.remove('hidden');
    messageContainer.classList.add(type === 'success' ? 'bg-green' : 'bg-red');
    messageContainer.innerText = message;

    setTimeout(() => {
        messageContainer.classList.add('hidden');
    }, 5000);
}

export const toggleDisableSubmitBtn = (submitButton: HTMLElement | null, isDisabled: boolean) => {
    submitButton?.setAttribute('disabled', isDisabled ? 'disabled' : '');

    if (isDisabled) {
        submitButton?.classList.add('cursor-not-allowed', 'opacity-50');
        submitButton?.classList.remove('cursor-pointer', 'opacity-100');
    } else {
        submitButton?.classList.remove('cursor-not-allowed', 'opacity-50');
        submitButton?.classList.add('cursor-pointer', 'opacity-100');
    }
};

const handleMailingInput = (formData: FormData) => {
    if(formData.get("mailing_ok") === 'on') {
        formData.set('mailing_ok', '1');
    } else {
        formData.set('mailing_ok', '0');
    }
}

export const handleInvestInput = (formSelector: string, formData: FormData) => {
    const form = document.querySelector(`.${formSelector}`);

    // @ts-ignore
    form?.querySelectorAll('input[name="habiter"]').forEach((input: HTMLInputElement) => {
        if(input.id === 'habiter') {
            if(input.checked) {
                input.value = '1';
                formData.set('habiter', '1');
            } else {
                input.value = '0';
                formData.set('habiter', '0');
            }
        } else if(input.id === 'is_investisseur') {
            if(input.checked) {
                input.value = '1';
                formData.append('is_investisseur', '1');
            } else {
                input.value = '0';
                formData.append('is_investisseur', '0');
            }
        } else {
            formData.set('habiter', '0');
            formData.append('is_investisseur', '0');
        }
    })
};

export const handleFormSubmit = async (
    formData: FormData,
    submitButton: HTMLElement | null,
    formDataName: string,
    formType: 'contact' | 'program' | 'rappel' | 'terrain' | 'brochure',
    gtmPayload?: Partial<GtmPayload>
) => {
    try {
        toggleDisableSubmitBtn(submitButton, true);

        handleInvestInput(formDataName, formData);
        handleMailingInput(formData);
    
        console.log('formData :', ...formData.entries());
        // @ts-ignore
        const response = await axios.post(urls.ajax, formData);

        if(response.status !== 200) throw new Error(`Failed to submit ${formType} form`);
        
        displayFormSubmitMessage('success', `Votre message a bien été envoyé`);

        if(gtmPayload) {
            gtmEvent('Lead', {
                ...gtmPayload,
                success: true
            });
        }

    } catch (error) {
        console.error(error);
        displayFormSubmitMessage('error', `Une erreur est survenue lors de l'envoi du message pour le formulaire ${formType}`);
        if(gtmPayload) {
            gtmEvent('Lead', {
                ...gtmPayload,
                success: false
            });
        }
    } finally {
        setTimeout(() => {
            toggleDisableSubmitBtn(submitButton, false);
            const form = document.querySelector(`.${formDataName}`);
            if(form) form.classList.add('hidden');
        }, 5000);
    }
};

export function debounce<F extends (...args: Parameters<F>) => void>(func: F, timeout: number) {
	let timeoutId: ReturnType<typeof setTimeout>;

	return (...args: Parameters<F>): void => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, timeout);
	};
}

export function handleClickOutsideForm(formContainer: HTMLElement) {
    const handleClick = (event: MouseEvent) => {
        const form = formContainer.querySelector('.form-element');
        setTimeout(() => {
            if (form && !formContainer.classList.contains('hidden')) {
                if (!form.contains(event.target as Node)) {
                    formContainer.classList.add('hidden');        
                }
            } 
        }, 10);
    }

    window.addEventListener('click', (event) => {
        event.stopPropagation();        
        handleClick(event);
    });
}