document.addEventListener('DOMContentLoaded', () => {
    const radioInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.radio-input-block');

    radioInputs?.forEach(input => {
        input?.addEventListener('click', (event) => {
            event.stopPropagation();
            const target = event?.target as HTMLElement;
            if(!target) return
            
            const input: HTMLInputElement | null = target.querySelector('input[type="radio"]');

            if(!!input) {
                input.checked = !input.checked;
                input.value = input.checked ? '1' : '0';
            }
        })
    })
})