// OLD JS FILES IMPORT
import "../scripts/includes/helpers";
import "../scripts/includes/menu";
import "../scripts/includes/panel";
import "../scripts/includes/return_top";
import "../scripts/includes/page";
import "../scripts/includes/collapse";
import "../scripts/includes/contactform";

import "@/utils/gsap.ts"
import "@/utils/forms.ts"
import "@/components/offers-slider.ts";
import "@/components/faq-block.ts";
import "@/components/program-card.ts";
import "@/components/menu-topbar.ts";
import "@/components/immodvisors.ts";
import "@/components/shortcut.ts";

// FIELDS
import "@/components/fields/textfield.ts";
import "@/components/fields/locationfield.ts";
import "@/components/fields/optinfield.ts";
import "@/components/fields/wishradioinputs.ts";

// MODALS
import "@/components/realisations-modal.ts";

// FORMS
import "@/components/forms/vendre-terrain-vendre.ts";
import "@/components/contact-form-block.ts";
import "@/components/brochure-form.ts";
import "@/components/plan-form.ts";
import "@/components/program/rappel-contact-form.ts";
import "@/components/program/program-contact-form.ts";
import "@/components/program/rappel-contact-form.ts";

// HOMEPAGE
import "@/components/homepage/hero-slider.ts";
import "@/components/homepage/residences-categories.ts";
import "@/components/homepage/header-search-form.ts";
import "@/components/homepage/offer-banner.ts";

// PROGRAM PAGE
import "@/components/program/avancement.ts";
import "@/components/program/map-program.ts";
// import "@/components/program/menu-program.ts";

// VUE COMPONENTS
import QuickSearchInput from "@/vue/forms/QuickSearchInput.vue";
import SearchFormHome from "@/vue/SearchFormHome.vue";
import DisplaySearchFormMenu from "@/vue/DisplaySearchFormMenu.vue";
import SearchResults from "@/vue/SearchResults.vue";
import ParrainageForm from "@/vue/ParrainageForm.vue";
import FAQ from "../scripts/components/Faq.vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// TEST JQUERY
// @ts-ignore
import $ from "jquery";
import _ from "lodash";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

document.addEventListener("DOMContentLoaded", () => {
    createApp(SearchFormHome)
        .use(pinia)
        .mount("#search-form-home");

    createApp(DisplaySearchFormMenu)
        .use(pinia)
        .mount("#search-form-menu-mobile");

    createApp(QuickSearchInput)
        .use(pinia)
        .mount("#quick-search-input");

    createApp(SearchResults)
        .use(pinia)
        .mount("#search-results");

    createApp(ParrainageForm)
        .use(pinia)
        .mount("#parrainage-form");

    createApp(FAQ)
        .use(pinia)
        .mount("#faq-section");
})
