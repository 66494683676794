export const disablePageScroll = (open: boolean) => {
    if(open) {
        document.body.style.overflow = 'hidden';
        // document.body.style.position = 'fixed';
        // document.body.style.top = `-${window.scrollY}px`;
    } else {
        document.body.style.overflow = 'auto'
        // document.body.style.position = '';
        // document.body.style.top = '';
    }
}