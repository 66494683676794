document.addEventListener("DOMContentLoaded", function () {
    const triggers: NodeListOf<HTMLElement> = document.querySelectorAll('.openRealisationsModal');

    triggers?.forEach(item => {
        item?.addEventListener('click', (event) => {
            const modalId = item.getAttribute('data-modal-id');
            if (modalId) {
                const modalContainer: HTMLElement | null = document.querySelector(`#${modalId}`);
                event.stopPropagation(); // Prevent the click from propagating to the window
                if (modalContainer) {
                    modalContainer.classList.toggle('hidden');
                    document.body.style.overflow = 'hidden';

                    const closeButton: HTMLElement | null = modalContainer.querySelector(".closeRealisationsModal");
                    closeButton?.addEventListener('click', () => {
                        if (modalContainer) {
                            modalContainer.classList.add('hidden');
                            document.body.style.overflow = 'auto';
                        }
                    });
                }
            }
        })
    });
});
