import { handleFormSubmit, handleClickOutsideForm } from '@/utils/forms';

document.addEventListener("DOMContentLoaded", function () {
    const form: HTMLFormElement | null = document.querySelector(".brochure-form");

    const submitButton: HTMLElement | null = document.querySelector(".submitBrochureForm");

    document.querySelectorAll('.openBrochureForm')?.forEach(item => {
        item?.addEventListener('click', (e) => {
            e.stopPropagation();

            if(form) form.classList.toggle('hidden');

            if(form && !form.classList.contains('hidden')) {
                handleClickOutsideForm(form);
            }
        })
    });

    document.querySelector('#closeBrochureForm')?.addEventListener('click', () => {
        form?.classList.add('hidden');
    })

    form?.addEventListener('submit', (event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        handleFormSubmit(formData, submitButton, 'brochure-form', 'brochure');      
    })
});
