<script setup lang="ts">
import { ref, watch } from 'vue'
import axios from 'axios';
import { storeToRefs } from 'pinia';

import { useSearchForm } from '@/composables/useSearchForm';

import { useSearchFormStore } from '@/stores/searchFormStore';
const searchStore = useSearchFormStore();
const { formPayload } = storeToRefs(searchStore);

interface ProgramPlace {
    key: string;
    value: string;
}

const {
    setSearchUrl
} = useSearchForm();

const search = ref('');
const results = ref([] as Array<ProgramPlace>);
const loading = ref(false as Boolean);
const onSearch = async () => {
    try {
        // @ts-expect-error
        const ajaxUrl = window?.urls?.ajax;
        const response = await axios.get(`${ajaxUrl}?action=search_autocomplete&search=${search.value}`)
        results.value = response.data;
    } catch (error) {
        console.error(error);
    }
}

const toggleItemOnModel = (result: ProgramPlace) => {
    search.value = result.value;
    formPayload.value.places = [result];
    searchStore.updateSearchQuery();
    setSearchUrl();
}

let bounce: NodeJS.Timeout;
watch(search, () => {
    loading.value = true;
    clearTimeout(bounce);
    bounce = setTimeout(() => {
        onSearch();
        loading.value = false;
    }, 500);
});

</script>

<template>
    <form
        @submit.prevent="setSearchUrl"
        class="max-[1405px]:hidden relative border border-primary rounded-md p-2 inline-flex gap-1 items-center w-[225px] m-0"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
            fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#640A32" />
            <path
                d="M11.5737 14.8149C13.3636 14.8149 14.8145 13.3639 14.8145 11.5741C14.8145 9.7843 13.3636 8.33337 11.5737 8.33337C9.78394 8.33337 8.33301 9.7843 8.33301 11.5741C8.33301 13.3639 9.78394 14.8149 11.5737 14.8149Z"
                stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.9716 16.8981L13.6221 14.5486" stroke="white" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        <input
            class="w-full pl-1 pr-2"
            type="text"
            v-model="search"
            placeholder="Trouvez votre logement"
        >
        <ul
            v-if="results.length"
            class="absolute z-30 w-full bg-white top-[2.6rem] left-0 max-h-80 overflow-y-scroll no-scrollbar shadow-md">
            <li
                class="p-3 pl-7 relative flex items-center gap-3 cursor-pointer hover:bg-primary/10"
                v-for="(result, index) in results"
                :key="index"
                @click="toggleItemOnModel(result)"
            >
                    {{ result.value }}
            </li>
        </ul>
    </form>
</template>
